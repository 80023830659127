import { Navigation, Autoplay } from 'swiper/modules';

export default function () {
    window.addEventListener('load', () => {
        import('swiper').then((module) => {
            const Swiper = module.default;
            const cs = getComputedStyle(document.documentElement);

            //MAIN BANNER
            const mainBanner = new Swiper('.main-banner', {
                modules: [Navigation, Autoplay],
                preloadImages: false,
                autoplay: {
                    delay: 7000
                },
                lazy: true,
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next.main-banner-button',
                    prevEl: '.swiper-button-prev.main-banner-button'
                }
            });

            //MAIN PAGE PRODUCTS
            const breakpoints = {
                1: {},
                375: {},
                601: {},
                1100: {},
                1280: {}
            };

            const slider1 = new Swiper('.main-page-products-wrapper.slider1 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button1',
                    prevEl: '.swiper-button-prev.slider-button1'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider1.on('breakpoint', function () {
                slider1.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider1.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider1.update();
            });

            const slider2 = new Swiper('.main-page-products-wrapper.slider2 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button2',
                    prevEl: '.swiper-button-prev.slider-button2'
                },
                loop: false
            });

            const slider3 = new Swiper('.main-page-products-wrapper.slider3 .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.slider-button3',
                    prevEl: '.swiper-button-prev.slider-button3'
                },
                loop: false,
                breakpoints: breakpoints,
                slidesPerView: +cs.getPropertyValue('--prodSwiperCols'),
                spaceBetween: +cs.getPropertyValue('--prodGap').replace('px', '')
            });

            slider3.on('breakpoint', function () {
                slider3.params.slidesPerView = +cs.getPropertyValue('--prodSwiperCols');
                slider3.params.spaceBetween = +cs.getPropertyValue('--prodGap').replace('px', '');
                slider3.update();
            });

            //PRODUCERS
            const producersBanner = new Swiper('.producers-banner .swiper', {
                modules: [Navigation],
                preloadImages: false,
                navigation: {
                    nextEl: '.swiper-button-next.producer-button',
                    prevEl: '.swiper-button-prev.producer-button'
                },
                loop: false,
                spaceBetween: 30,
                breakpoints: {
                    1: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    },
                    601: {
                        slidesPerView: 4
                    },
                    1100: {
                        slidesPerView: 5
                    },
                    1280: {
                        slidesPerView: 6
                    },
                    1681: {
                        slidesPerView: 7
                    }
                }
            });
        });
    }, false);
}